@font-face {
font-family: 'GeistSans';
src: url(/_next/static/media/e11418ac562b8ac1-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 100 900;
}@font-face {font-family: 'GeistSans Fallback';src: local("Arial");ascent-override: 85.83%;descent-override: 20.52%;line-gap-override: 9.33%;size-adjust: 107.19%
}.__className_3a0388 {font-family: 'GeistSans', 'GeistSans Fallback'
}.__variable_3a0388 {--font-geist-sans: 'GeistSans', 'GeistSans Fallback'
}

@font-face {
font-family: 'GeistMono';
src: url(/_next/static/media/66f30814ff6d7cdf.p.woff2) format('woff2');
font-display: swap;
font-weight: 100 900;
}.__className_c1e5c9 {font-family: 'GeistMono', ui-monospace, SFMono-Regular, Roboto Mono, Menlo, Monaco, Liberation Mono, DejaVu Sans Mono, Courier New, monospace
}.__variable_c1e5c9 {--font-geist-mono: 'GeistMono', ui-monospace, SFMono-Regular, Roboto Mono, Menlo, Monaco, Liberation Mono, DejaVu Sans Mono, Courier New, monospace
}

